import React, { Fragment } from "react";
import Logo from "../assets/images/upload-web/Zenbase-Watermark-Purple.png";

const ThankYou = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  return (
    <Fragment>
      <div className="bg-img">
        <div className="upload-block">
          <div>
            <img className="logo" src={Logo} alt="logo" />
          </div>
          <div className="box thank-you-box text-white text-center">
            <h1 className="title">
              Thank you, <span>{user.name}</span>
            </h1>
            <p className="title-message">
              We’ll send you an email update if this <br></br> wellness content
              meets our guidelines.
            </p>
            <h2 className="mb-0">Namaste</h2>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ThankYou;
