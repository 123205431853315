import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Login from "./pages/Login";
import Upload from "./pages/Upload";
import ThankYou from "./pages/ThankYou";
import { useHistory } from "react-router-dom";
import axios from "axios";
import config from "./config";

axios.defaults.baseURL = config.api;
function App() {
  let history = useHistory();
  let token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      history.push("/");
    } else {
      history.push("/upload");
    }
    // eslint-disable-next-line
  }, [token]);
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/upload" component={Upload} />
      <Route exact path="/thank-you" component={ThankYou} />
    </Switch>
  );
}

export default App;
