import React, { Fragment, useState } from "react";
import Logo from "../assets/images/upload-web/Zenbase-Watermark-Purple.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useHistory } from "react-router-dom";

const Login = () => {
  const [error, setError] = useState("");
  let history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Please provide a valid email.")
        .required("Email is required."),
      password: Yup.string().required("Password is required."),
    }),
    onSubmit: (values) => onSubmit(values),
  });

  const onSubmit = (values) => {
    if (formik.isValid) {
      axios
        .post("/auth/login", values)
        .then((res) => {
          if (res) {
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem("user", JSON.stringify(res.data.data.user));
            history.push("/upload");
          }
        })
        .catch((err) => {
          setError(err.response.data.error);
          formik.setSubmitting(false);
        });
    }
  };

  return (
    <Fragment>
      <div className="bg-img">
        <div className="upload-block">
          <div>
            <img className="logo" src={Logo} alt="logo" />
          </div>
          <div className="box login-box text-white text-center">
            <h1 className="title">
              Zenbase <span>Upload</span>
            </h1>
            <p className="title-message">
              Log in with your Zenbase credentials to upload your collection of
              wellness content.
            </p>

            <form className="login-form" onSubmit={formik.handleSubmit}>
              {error ? (
                <div className="form-group alert alert-danger" role="alert">
                  {error}
                </div>
              ) : null}
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className={
                    !formik.errors.email ? "form-control" : "form-control error"
                  }
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.errors.email && formik.touched.email ? (
                  <span>{formik.errors.email}</span>
                ) : null}
              </div>
              <div className="form-group mb-0">
                <label>Password</label>
                <input
                  type="password"
                  className={
                    !formik.errors.password
                      ? "form-control"
                      : "form-control error"
                  }
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.errors.password && formik.touched.password ? (
                  <span>{formik.errors.password}</span>
                ) : null}
              </div>

              <div className="btn-block">
                <button
                  type="submit"
                  className="btn btn-custom btn-next w-100"
                  disabled={formik.isSubmitting}
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
