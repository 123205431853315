import React, { Fragment, useEffect, useRef, useState } from "react";
import Logo from "../assets/images/upload-web/Zenbase-Watermark-Purple.png";
import icons from "../assets/images/upload-web/icons.png";
import Artwork from "../assets/images/upload-web/Subtract.png";
// import FileIcon from "../assets/images/upload-web/file.png";
import axios from "axios";
import UploadFile1 from "../assets/images/upload-web/upload-1.png";
import UploadFile2 from "../assets/images/upload-web/upload-2.png";
import { useHistory } from "react-router-dom";
import RSelect from "react-select";
import Creatable from "react-select/creatable";
import { Formik } from "formik";
import * as yup from "yup";

const Upload = () => {
  let history = useHistory();
  const [duration, setDuration] = useState();
  const [categories, setCategories] = useState([]);
  const [artists, setArtists] = useState([]);
  const sourceFileRef = useRef();
  const artworkFileRef = useRef();

  const initialValues = {
    name: "",
    categories: [],
    artists: [],
    tags: [],
    artwork: "",
    source: "",
  };

  const validationSchema = yup.object({
    name: yup.string().required(),
    categories: yup.array().min(1, "at least 1").required("required"),
    artists: yup.array().min(1, "at least 1").required("required"),
    tags: yup.array().min(1, "at least 1").required("required"),
    source: yup
      .mixed()
      .required("A File is required")
      .test(
        "source",
        "Invalid Source format.(Only MP3, MP4, MOV, GIF, WEBM allowed.)",
        async (file) => {
          if (file) {
            let checkFile = await onChangeSource(file);
            return checkFile;
          }
        }
      ),
    artwork: yup
      .mixed()
      .required("A File is required")
      .test(
        "artwork",
        "Invalid Source format.(Only JPG, JPEG, PNG allowed.)",
        async (file) => {
          if (file) {
            let checkFile = await onChangeArtwork(file);
            return checkFile;
          }
        }
      ),
  });

  async function onChangeSource(file) {
    let files = ["mp3", "mp4", "mov", "gif", "webm", "zip"];
    if (!files.includes(file.name.split(".").pop())) {
      return false;
    } else {
      const _duration = await computeDuration(file);
      setDuration(Math.floor(_duration));
      return true;
    }
  }

  async function onChangeArtwork(file) {
    let files = ["png", "jpg", "jpeg"];
    if (!files.includes(file.name.split(".").pop())) {
      return false;
    } else {
      return true;
    }
  }

  function computeDuration(file) {
    return new Promise((resolve) => {
      var objectURL = URL.createObjectURL(file);
      var mySound = new Audio([objectURL]);
      mySound.addEventListener(
        "canplaythrough",
        () => {
          URL.revokeObjectURL(objectURL);
          resolve(mySound.duration);
        },
        false
      );
    });
  }

  const onSubmit = (values) => {
    const payload = new FormData();
    payload.append("name", values.name);
    payload.append(
      "artist",
      values.artists.map((artist) => artist.value).join(",")
    );
    payload.append("tags", values.tags.map((tag) => tag.value).join(","));
    payload.append(
      "categories",
      values.categories.map((category) => category.value).join(",")
    );
    payload.append("source", values.source);
    payload.append("artwork", values.artwork);
    payload.append("duration", duration || 0);
    axios.post("/front-web/upload-song", payload).then((res) => {
      if (res) {
        history.push("/thank-you");
      }
    });
  };

  const fetchCategories = async () => {
    const { data } = await axios.get("/admin/categories");
    setCategories(data.data);
  };

  const fetchUsers = async () => {
    const { data } = await axios.get("/admin/users/artists");
    setArtists(data.data);
  };

  useEffect(() => {
    fetchCategories();
    fetchUsers();
    // eslint-disable-next-line
  }, []);

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "grey",
      textAlign: "left",
    }),
    placeholder: (provided) => ({
      ...provided,
      marginLeft: 0,
      position: "absolute",
      left: 0,
    }),
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        handleSubmit,
        values,
        setFieldValue,
        isSubmitting,
        errors,
        touched,
        isValid,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="bg-img">
            <div className="upload-block">
              <div>
                <img className="logo" src={Logo} alt="logo" />
              </div>
              <div className="box text-white text-center">
                <h1 className="title">
                  Zenbase <span>Upload</span>
                </h1>
                <p className="title-message mb-40">
                  Upload your collection of wellness content. <br></br>{" "}
                  Meditation music, yoga courses, and everything in between.
                </p>

                <div className="row g-4">
                  <div className="col-md-6">
                    <input
                      type="text"
                      className={
                        errors.name && touched.name
                          ? "form-control form-control-input error"
                          : "form-control form-control-input"
                      }
                      placeholder="Enter Song Name"
                      onChange={(e) => setFieldValue("name", e.target.value)}
                      id="name"
                    />
                  </div>
                  <div className="col-md-6">
                    <RSelect
                      styles={customStyles}
                      className={
                        errors.categories && touched.categories ? "error-1" : ""
                      }
                      options={categories.map((category) => {
                        return {
                          label: category.name,
                          value: category._id,
                        };
                      })}
                      onChange={(item) => setFieldValue("categories", item)}
                      placeholder="Categories"
                      id="categories"
                      isMulti
                    />
                  </div>
                  <div className="col-md-6">
                    <RSelect
                      styles={customStyles}
                      className={
                        errors.artists && touched.artists ? "error-1" : ""
                      }
                      options={artists.map((artist) => {
                        return {
                          label: artist.name,
                          value: artist._id,
                        };
                      })}
                      onChange={(item) => setFieldValue("artists", item)}
                      placeholder="Artists"
                      id="artists"
                      isMulti
                    />
                  </div>
                  <div className="col-md-6">
                    <Creatable
                      styles={customStyles}
                      className={errors.tags && touched.tags ? "error-1" : ""}
                      options={values?.tags}
                      value={values?.tags}
                      onChange={(items) => {
                        setFieldValue("tags", items);
                      }}
                      onCreateOption={(option) => {
                        setFieldValue("tags", [
                          ...values.tags,
                          { label: option, value: option },
                        ]);
                      }}
                      placeholder="Tags"
                      id="tags"
                      isMulti
                    />
                  </div>
                  <div className="col-md-6">
                    <div
                      className={
                        (errors.source && values.source) ||
                        (!values.source && touched.source)
                          ? "child-box child-box-error"
                          : "child-box"
                      }
                    >
                      <div className="child-box-padding">
                        {!errors.source && values.source ? (
                          <Fragment>
                            <div className="upload-img">
                              <img src={UploadFile1} alt="file-icon" />
                            </div>

                            <p className="para">
                              <span>{values.source.name}</span>
                            </p>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <img src={icons} alt="icon" />
                            <h4 className=" title-message ">Audio or Video</h4>
                            <p className="para">
                              Upload a MP3, MP4, MOV, or GIF.{" "}
                              <span>Use a .ZIP for multiple files.</span>
                            </p>
                          </Fragment>
                        )}
                      </div>
                      <div className="form-container">
                        <div
                          className="btn btn-custom btn-choose"
                          onClick={() => sourceFileRef.current.click()}
                        >
                          <span className="button-">
                            {!errors.source && values.source
                              ? "Replace File"
                              : "Choose File"}
                          </span>
                        </div>
                        <input
                          ref={sourceFileRef}
                          type="file"
                          multiple
                          name="source"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            setFieldValue("source", e.target.files[0])
                          }
                          accept=".mp3, .mp4, .mov, .gif, .webm, .zip"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className={
                        (errors.artwork && values.artwork) ||
                        (!values.artwork && touched.artwork)
                          ? "child-box child-box-error"
                          : "child-box"
                      }
                    >
                      <div className="child-box-padding">
                        {!errors.artwork && values.artwork ? (
                          <Fragment>
                            <div className="upload-img">
                              <img src={`${UploadFile2}`} alt="file-icon" />
                            </div>

                            <p className="para">
                              <span>{values.artwork.name}</span>
                            </p>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <img src={Artwork} alt="artwork" />
                            <h4 className="title-message">Album Artwork</h4>
                            <p className="para"> Upload a JPG or PNG.</p>
                          </Fragment>
                        )}
                      </div>
                      <div className="form-container">
                        <div
                          className="btn btn-custom btn-choose"
                          onClick={() => artworkFileRef.current.click()}
                        >
                          <span className="button-">
                            {!errors.artwork && values.artwork
                              ? "Replace File"
                              : "Choose File"}
                          </span>
                        </div>

                        <input
                          ref={artworkFileRef}
                          type="file"
                          multiple
                          name="artwork"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            setFieldValue("artwork", e.target.files[0])
                          }
                          accept=".png, .jpg, .jpeg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-4 text-center ">
                    <button
                      type="submit"
                      className="btn btn-custom btn-next btn-white"
                      disabled={isValid && isSubmitting}
                    >
                      Upload
                    </button>
                    {/* <button disabled className="btn btn-custom btn-next">
                    Next
                  </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Upload;
